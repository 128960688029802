<template>
  <div>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
        <KTPortlet>
          <template v-slot:title>
            {{ $t("studentsList.title") }} // <span class="group-name">Класс </span> - {{ groupInfo.groupName }}
          </template>
          <template v-slot:toolbar>
            <b-button v-b-modal.modal-1>{{ $t("common.new") }}</b-button>
            <b-button @click="exportStudentData">{{ $t("common.export") }}</b-button>
            <b-button @click="exportPasswords">{{ $t("common.exportPasswords") }}</b-button>
          </template>
          <template v-slot:body>
            <div class="alert alert-danger">
              Дорогие учителя.
              Если есть вопросы сразу обращайтесь к завучу или отвественному по школе за дневник.
              По ссылке можете найти видеоинструкцию
              <a href="https://www.youtube.com/watch?v=3q7y3k_NImU&list=PL7rW8E5_3asAGTuOxNkLFMfCoGbv5-zDZ&index=4"
                 target="_blank" style="font-style: italic; color: black; 
                   text-decoration: underline; font-weight: bold">Инструкция</a>
            </div>
            <div class="">
              <b-form-group>
                <template v-slot:label>
                  
                </template>
                <b-form-checkbox
                    v-model="showArchive"
                    @change="filterStudents"
                >
                  {{ $t("common.showDroppedStudents") }}
                </b-form-checkbox>
              </b-form-group>
            </div>
            
            <table class="table table-bordered">
              <thead class="thead-light">
              <tr>
                <th>#</th>
                <th class="text-left">
                  {{ $t("studentsList.inn") }}
                </th>
                <th class="text-left">
                  {{ $t("studentsList.firstName") }}
                </th>
                <th class="text-left">{{ $t("studentsList.name") }}</th>
                <th class="text-left">
                  {{ $t("studentsList.patronymic") }}
                </th>
                <th class="text-left">
                  {{ $t("studentsList.contactPhone") }}
                </th>   
                <th class="text-left">
                  {{ $t("studentsList.sex") }}
                </th>
                <th class="text-left">
                  {{ $t("studentsList.socialPassportCategory") }}
                </th>
                <th class="text-left">
                  {{ $t("studentsList.userName") }}
                </th>
                <th class="text-left"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, k) in studentsFiltered" :key="item.id" :class="isDropped(item)">
                <td>{{ k + 1 }}</td>
                <td>{{ item.inn }}</td>
                <td>{{ item.firstName }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.patronymic }}</td>
                <td>{{ item.contactPhone }}</td>
                <td>{{ showSex(item.sex) }}</td>
                <td>{{ item.socialPassportCategoryValue }}</td>
                <td>{{ item.userName }}</td>
                <td>
                  <a class="btn btn-icon btn-success" :href="'#/studentInfo/' + item.studentToGroupId">
                    <i class="fa fa-pen"></i>
                  </a>
                  <b-button
                      type="submit"
                      @click="deleteStudentFromGroup(item)"
                      variant="primary"
                      class="btn btn-icon btn-danger"
                      style="margin-left: 10px"
                      v-if="showForAdmin()"
                  >
                    <i class="fa fa-trash"></i>
                  </b-button>
                  
<!--                  <b-button class="btn btn-icon btn-success"
                            type="submit"
                            @click="editStudent(item)"
                            variant="primary"
                            :title="$t('common.edit')"
                  >
                    <i class="flaticon2-edit"></i>
                  </b-button>-->
<!--                  <b-button
                      type="submit"
                      @click="dropStudent(item)"
                      variant="primary"
                  >
                    {{ $t("studentsList.drop.button") }}
                  </b-button>-->
                  <!--<b-button
                    @click="openStudentDashboard(item)"
                    variant="primary"
                  >
                    {{ $t("timeTable.title") }}
                  </b-button>-->
                </td>
              </tr>
              </tbody>
            </table>
            <div>
              <b-modal id="modal-1" ref="student-modal">
                <template v-slot:modal-title>
                  {{ $t("common.new") }}
                </template>
                <b-form v-if="show">
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("studentsList.inn") }}
                    </template>
                    <b-form-input
                        v-model="form.inn"
                        :state="studentModelStates.inn"
                        @change="changeInn"
                    ></b-form-input>
                    <div class="invalid-feedback">{{ studentModelSaveError.inn }}</div>
                  </b-form-group>
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("studentsList.firstName") }}
                    </template>
                    <b-form-input
                        v-model="form.firstName"
                        :state="studentModelStates.firstName"
                    ></b-form-input>
                    <div class="invalid-feedback">{{ studentModelSaveError.firstName }}</div>
                  </b-form-group>

                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("studentsList.name") }}
                    </template>
                    <b-form-input
                        v-model="form.name"
                        :state="studentModelStates.name"
                    ></b-form-input>
                    <div class="invalid-feedback">{{ studentModelSaveError.name }}</div>
                  </b-form-group>
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("studentsList.patronymic") }}
                    </template>
                    <b-form-input
                        v-model="form.patronymic"
                        required
                    ></b-form-input>
                  </b-form-group>

                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("studentsList.birthDate") }}
                    </template>
                    <b-form-datepicker
                        v-model="form.birthDate"
                        :state="studentModelStates.birthDate"
                        locale="ru"
                        start-weekday="1"
                    ></b-form-datepicker>
                    <div class="invalid-feedback">{{ studentModelSaveError.birthDate }}</div>
                  </b-form-group>
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("studentsList.sex") }}
                    </template>
                    <b-form-select                        
                        v-model="form.sex"
                        :state="studentModelStates.sex"
                        :options="sexOptions"
                    ></b-form-select>
                    <div class="invalid-feedback">{{ studentModelSaveError.sex }}</div>
                  </b-form-group>
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("studentsList.contactPhone") }}
                    </template>
                    <b-form-input
                        v-model="form.contactPhone"
                    ></b-form-input>
                  </b-form-group>
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("studentsList.socialPassportCategory") }}
                    </template>
                    <b-form-select
                        v-model="form.socialPassportCategoryId"
                        :options="socialPassportCategories"
                    ></b-form-select>
                  </b-form-group>
                </b-form>
                <template v-slot:modal-footer>
                  <b-button type="submit" @click="onSubmit" variant="primary" id="save-student-data-button">{{
                      $t("common.save")
                    }}
                  </b-button>
                  <b-button type="reset" @click="onReset" variant="danger">{{
                      $t("common.cancel")
                    }}
                  </b-button>
                </template>
              </b-modal>
            </div>
            <div>
              <b-modal id="student-drop-modal" ref="student-drop-modal">
                <template v-slot:modal-title>
                  {{ $t("studentsList.drop.title") }}
                </template>
                <b-form v-if="show">
                  <div class="alert alert-primary">
                    {{ dropStudentName }}
                  </div>

                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("studentsList.drop.form.date") }}
                    </template>
                    <b-form-datepicker
                        v-model="dropForm.dropDate"
                        :state="studentDropModelStates.dropDate"
                        locale="ru"
                        start-weekday="1"
                    ></b-form-datepicker>
                    <div class="invalid-feedback">{{ studentDropModelSaveError.dropDate }}</div>
                  </b-form-group>
                  <b-form-group>
                    <template v-slot:label>
                      {{ $t("studentsList.drop.form.reason") }}
                    </template>
                    <b-form-select
                        v-model="dropForm.dropReasonId"
                        :options="dropReasons"
                        :state="studentDropModelStates.dropReasonId"
                    ></b-form-select>
                    <div class="invalid-feedback">{{ studentDropModelSaveError.dropReasonId }}</div>
                  </b-form-group>
                </b-form>
                <template v-slot:modal-footer>
                  <b-button type="submit" @click="saveDropStudent" variant="primary">{{
                      $t("common.save")
                    }}
                  </b-button>
                  <b-button type="reset" @click="closeDropForm" variant="danger">{{
                      $t("common.cancel")
                    }}
                  </b-button>
                </template>
              </b-modal>
            </div>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>
<style>
.dropped td {
  background-color: #F64E60;
}
</style>
<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import DictionariesService from "@/common/dictionaries.service";
import {mapGetters} from "vuex";

export default {
  name: "students",
  components: {
    KTPortlet
  },
  data() {
    return {
      groupId: null,
      students: null,
      dialog: true,
      socialPassportCategories: [],
      dropReasons: [],
      form: {
        inn: "",
        firstName: "",
        name: "",
        patronymic: "",
        parentName: "",
        contactPhone: "",
        socialPassportCategoryId: "",
        birthDate: "",
        sex: null
      },
      dropForm: {
        dropDate: "",
        dropReasonId: "",
        studentToGroupId: "",
      },
      studentModelStates: {},
      studentModelSaveError: {},
      studentDropModelStates: {},
      studentDropModelSaveError: {},
      show: true,
      groupInfo: {},
      dropStudentName: "",
      sexOptions:DictionariesService.sexOptions(),
      showArchive:false,
      studentsFiltered: []
    };
  },
  mounted() {
    this.reloadData();
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "currentUser"
    ])
  },
  methods: {
    onSubmit(evt) {
      let submitButton = document.getElementById('save-student-data-button');
      submitButton.classList.add("kt-spinner", "kt-spinner--light", "disabled");

      evt.preventDefault();
      let $this = this;
      this.form.groupId = this.$route.params.id;
      this.studentModelStates = {};
      this.studentModelSaveError = {};

      ApiService.postSecured("students/save", this.form)
          .then(function () {
            $this.$refs["student-modal"].hide();
            $this.reloadData();
            $this.cleanModel();
          })
          .catch(({response}) => {
            if (response.data != null && response.data.errors != null) {
              $this.studentModelSaveError = response.data.errors;

              for (let i in response.data.errors) {

                $this.studentModelStates[i] = false;
              }
            }
          })
          .finally(function () {
            submitButton.classList.remove("kt-spinner", "kt-spinner--light", "disabled");
          });
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form = {};

      this.$refs["student-modal"].hide();
    },
    dropStudent(student) {
      this.dropForm = {
        studentToGroupId: student.studentToGroupId,
        dropDate: student.dropDate,
        dropReasonId: student.dropReasonId,
      };
      this.dropStudentName = student.firstName + " " + student.name;
      this.$refs["student-drop-modal"].show();
    },
    deleteStudentFromGroup(student) {
      if(!confirm('Удалить ученика из класса? Потнряете все данные на ученика')){
        return;
      }
      let $this = this;
      let data = {
        id:student.studentToGroupId
      }
      
      ApiService.postSecured("students/deleteStudentFromGroup", data)
          .then(function () {
            $this.reloadData();
          })
          .catch(({response}) => {
            console.log(response);
          });
      
    },
    saveDropStudent(evt) {
      evt.preventDefault();
      let $this = this;

      ApiService.postSecured("students/drop", this.dropForm)
          .then(function () {
            $this.$refs["student-drop-modal"].hide();

            $this.reloadData();
            $this.cleanModel();
          })
          .catch(({response}) => {

            if (response.data != null && response.data.errors != null) {
              $this.studentDropModelSaveError = response.data.errors;

              for (let i in response.data.errors) {

                $this.studentDropModelStates[i] = false;
              }
            } else {
              $this.studentDropModelSaveError = response.data;

              for (let i in response.data) {

                $this.studentDropModelStates[i] = false;
              }
            }
          });
    },
    closeDropForm(evt) {
      evt.preventDefault();
      // Reset our form values
      this.dropForm = {};

      this.$refs["student-drop-modal"].hide();
    },
    reloadData() {
      let $this = this;
      ApiService.querySecured("students/list", {
        params: {groupId: this.$route.params.id}
      }).then(({data}) => {
        $this.students = data.list;
        $this.filterStudents();
        
      }).catch(({response}) => {
        console.log(response);
      });

      ApiService.querySecured("dictionaries/socialPassportCategories").then(({data}) => {
        $this.socialPassportCategories = data.dictionary;
      }).catch(({response}) => {
        console.log(response);
      });

      ApiService.querySecured("dictionaries/dropReasons").then(({data}) => {
        $this.dropReasons = data.dictionary;
      }).catch(({response}) => {
        console.log(response);
      });

      ApiService.querySecured("studentGroups/find", {
        params: {id: this.$route.params.id}
      }).then(({data}) => {
        $this.groupInfo = data;
      }).catch(({response}) => {
        console.log(response);
      });
    },
    cleanModel() {
      this.form = {
        inn: "",
        firstName: "",
        name: "",
        patronymic: "",
        parentName: "",
        contactPhone: "",
        socialPassportCategoryId: "",
        birthDate: "",
        sex: null
      };
      this.dropForm = {};
      this.dropStudentName = "";
    },
    editStudent(student) {
      this.studentModelStates = {};
      this.studentModelSaveError = {};

      this.form = student;
      this.$refs["student-modal"].show();
    },
    isDropped(student) {
      return student.isDropped === true ? "table-danger" : "";
    },
    showSex(sexId){
      for (let i in this.sexOptions){
        let sex = this.sexOptions[i];

        if(sex.value == sexId){
          return sex.text;
        }
      }

      return '';
    },
    exportPasswords(){

        ApiService.downloadSecured("students/exportToExcel?groupId=" + this.$route.params.id)
          .then(({data}) => {
            const url = URL.createObjectURL(new Blob([data], {
              type: 'application/vnd.ms-excel'
            }));
            const link = document.createElement('a');
            link.href = url;

            link.setAttribute('download', 'Ученики пароли.xlsx');
            document.body.appendChild(link);
            link.click();
          })
          .catch(({ response }) => {
            console.log(response);
          });
    },
    exportStudentData(){

        ApiService.downloadSecured("students/exportToExcelFullData?groupId=" + this.$route.params.id)
          .then(({data}) => {
            const url = URL.createObjectURL(new Blob([data], {
              type: 'application/vnd.ms-excel'
            }));
            const link = document.createElement('a');
            link.href = url;

            link.setAttribute('download', 'Ученики.xlsx');
            document.body.appendChild(link);
            link.click();
          })
          .catch(({ response }) => {
            console.log(response);
          });
    },
    changeInn(){
      let inn = this.form.inn.replace(/[^\d]/g, '');

      if (inn.length >= 9) {
        let sex = inn.substring(0, 1);

        console.log(sex);
        if (sex === '1') {
          this.form.sex = 1;
        }

        if (sex === '2') {
          this.form.sex = 2;
        }

        this.form.birthDate = new Date(parseInt(inn.substring(5, 9)), parseInt(inn.substring(3, 5)) - 1,  parseInt(inn.substring(1, 3)));
      }
    },
    filterStudents(){      
      this.studentsFiltered = this.students
          .filter((student)=> this.showArchive === true || student.isDropped === false);
    },
    showForAdmin(){
      return this.currentUser.role == 'ROLE_SCHOOL_ADMIN';
    },
    
    /*,
    openStudentDashboard(student) {
      this.$router.push({
        name: "studentDashboard",
        params: { id: student.id }
      });
    }*/
  }
};
</script>
